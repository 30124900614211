<template>
  <div class="reservation">
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="small" type="primary" @click="handleAddFirstLevelTag">
            添加一级分类
          </el-button>
        </div>
      </div>
      <div class="tag-list">
        <div class="tags" v-for="(item, index) in tableData" :key="item.code">
          <div class="first">
            <div class="title">{{ item.name }}</div>
            <div class="action">
              <el-button @click="handleEditTag(item)" type="text">修改</el-button>
              <el-button @click="handleDeleteTag(item)" type="text" style="color: red;">删除</el-button>
            </div>
          </div>
          <div class="second">
            <el-tag
              v-for="tag in item.child"
              :key="tag.code"
              closable
              :disable-transitions="false"
              @close="handleDeleteTag(tag)"
              style="margin-right: 10px;"
              >
              {{tag.name}}
            </el-tag>
            <el-button class="button-new-tag" size="mini" @click="handleAddSecondLevelTag(item)">添加标签</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.show"
      width="40%"
      @close="dialogClose"
    >
    <el-dialog
        title="选择店铺"
        :visible.sync="innerVisible"
        append-to-body
        :close-on-click-modal="false"
        custom-class="mainShopDialog"
        @close="handleClose"
      >
        <p>选择楼层</p>
        <el-input
          v-model="filterText"
          placeholder="请输入店铺名称"
          size="medium"
          style="width: 260px; margin-top: 10px;"
          @change="filterShop"
        ></el-input>
        <el-tabs class="floor mainShopFloor" v-model="dialog.activeName">
          <el-tab-pane
            v-for="(item, index) in childList"
            :key="index"
            :label="item.name"
            :name="item.name"
          >
            <div class="floorShop">
              <el-checkbox-group
                v-model="dialog.checked"
                @change="handleChange"
              >
                <el-checkbox
                  :label="shop.shopName + 'id:' + shop.id"
                  v-for="(shop, index) in item.childList"
                  :key="index"
                >
                  {{ shop.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerDialogCancel">取 消</el-button>
          <el-button type="primary" @click="innerDialogConfirm">确定</el-button>
        </div>
      </el-dialog>
      <el-form
        ref="form"
        :rules="formRules"
        :model="dialog"
        label-width="100px"
      >
        <el-form-item label="所属店铺：" prop="shopName">
          <el-select
            v-model="dialog.shopName"
            placeholder="请选择店铺"
            @click.native="selectShopEvent"
            no-data-text=""
            style="width: 200px;"
            size="small"
          >
            <el-option
              v-for="item in shopOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务名称：" prop="name">
          <el-input
            v-model="dialog.name"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务说明：" prop="desc">
          <el-input
            type="textarea"
            v-model="dialog.serviceExplain"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" v-throttle @click="confirmDialog">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="tagDialog.title" :visible.sync="tagDialog.show" @close="handleTagDialogClose" width="40%">
        <el-form
          ref="tagForm"
          :rules="tagFormRules"
          :model="tagDialog"
          label-width="100px"
        >
          <el-form-item label="标签名称：" prop="name">
            <el-input
              v-model="tagDialog.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签编码：" prop="code">
            <el-input
              v-model="tagDialog.code"
              :disabled="tagDialog.disabled"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="handleTagCancel">取 消</el-button>
            <el-button type="primary" @click="handleTagConfirm">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from '@/components/d-search'
import DTable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import { options } from '@/components/options'
export default {
  data () {
    return {
      dialog: {
        id: undefined,
        show: false,
        title: null,
        disabled: false,
        name: '',
        shopName: '',
        serviceExplain: '',
        activeName: '',
        checked: [],
        action: ''
      },
      tagDialog: {
        title: '',
        show: false,
        name: '',
        code: '',
        pid: 0,
        typeLevel: 0,
        action: '',
        id: 0
      },
      formRules: {
        shopName: this.$formValidator.empty('所属店铺', 'change'),
        name: this.$formValidator.empty('服务名称')
      },
      columnData: [
        { prop: 'name', label: '服务名称' },
        { prop: 'serviceExplain', label: '服务说明', width: 120 },
        { prop: 'shopName', label: '店铺' },
        { prop: 'createUserName', label: '创建人' },
        { prop: 'createTime', label: '创建时间' },
        { prop: 'updateTime', label: '更新时间' },
        {
          prop: 'operate',
          label: '操作',
          width: 220,
          type: {}
        }
      ],
      tableData: [
      ],
      inputValue: '',
      tagFormRules: {
        name: this.$formValidator.empty('标签名称'),
        code: this.$formValidator.empty('标签编码')
      },
      /* 店铺列表 */
      shopOptions: [{}],
      childList: [],
      allShopList: [],
      /* 弹窗 */
      innerVisible: false,
      filterText: '', // 店铺搜索
      checkedShopIds: [],
      checkedShopName: '',
      orgChecked: [] // 打开店铺选择框之前选择的店铺
    }
  },
  components: {
    DSearch,
    DTable,
    DPaging
  },
  created () {
    this.getItemList()
    this.getShopList()
  },
  // 方法集合
  methods: {
    handleTagDialogClose () {
      this.$refs.tagForm && this.$refs.tagForm.resetFields()
    },
    // 确认添加标签
    handleTagConfirm () {
      this.$refs.tagForm.validate((valid) => {
        if (valid) {
          const { code, name, pid, typeLevel, action, id } = this.tagDialog
          if (action == 'add') {
            const postData = {
              code,
              name,
              pid,
              typeLevel
            }
            this.$api.shopManageApi.addGoodsTag(postData).then(res => {
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.getItemList()
                this.tagDialog.show = false
              } else {
                this.$message.error(res.msg)
              }
            })
          } else if (action == 'edit') {
            const postData = {
              name,
              id
            }
            this.$api.shopManageApi.updateGoodsTag(postData).then(res => {
              if (res.code === 200) {
                this.$message.success('修改成功')
                this.getItemList()
                this.tagDialog.show = false
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    },
    // 取消添加标签
    handleTagCancel () {
      this.tagDialog.show = false
    },
    // 添加标签
    handleAddFirstLevelTag () {
      const { tagDialog } = this
      tagDialog.title = '新增一级标签'
      tagDialog.show = true
      tagDialog.pid = 0
      tagDialog.typeLevel = 1
      tagDialog.action = 'add'
    },
    // 添加标签
    handleAddSecondLevelTag (target) {
      const { tagDialog } = this
      tagDialog.title = '新增二级标签'
      tagDialog.show = true
      tagDialog.pid = target.id
      tagDialog.typeLevel = target.typeLevel + 1
      tagDialog.action = 'add'
    },
    // 修改标签
    handleEditTag (target) {
      const { tagDialog } = this
      tagDialog.title = '修改一级标签'
      tagDialog.show = true
      tagDialog.id = target.id
      tagDialog.name = target.name
      tagDialog.code = target.code
      tagDialog.action = 'edit'
      tagDialog.disabled = true
    },
    // 删除标签
    handleDeleteTag (target) {
      this.$api.shopManageApi.deleteGoodsTag({ id: target.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.getItemList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 过滤店铺
    filterShop(val) {
      if (val) {
        this.childList.forEach((item) => {
          item.childList = item.childList.filter((shop) =>
            shop.name.includes(val),
          )
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },
    
    // 显示选择店铺弹窗
    selectShopEvent() {
      // 编辑的时候所属店铺不能选择
      // if (this.dialogTitle.includes('编辑')) {
      //   return
      // }
      this.filterText = ''
      this.childList = JSON.parse(JSON.stringify(this.allShopList))
      this.orgChecked = this.dialog.checked
      this.innerVisible = true
    },
    // 里层弹窗确认
    innerDialogConfirm() {
      this.dialog.shopName = ''
      this.orgChecked = this.dialog.checked
      this.dialog.checked.forEach(item => {
        const arr = item.split('id:')
        this.checkedShopIds.push(arr[1])
        this.dialog.shopName += `${arr[0]},`
      })
      this.innerVisible = false
    },
    innerDialogCancel () {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleClose () {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleResetDialog () {
      this.dialog = Object.assign(this.dialog, {
        id: undefined,
        show: false,
        title: null,
        disabled: false,
        name: '',
        shopName: '',
        serviceExplain: '',
        // activeName: '',
        checked: [],
        action: ''
      })
      
    },
    // 选择店铺
    handleChange(v) {
      if (this.dialog.action == 'edit' ) {
        this.dialog.checked.length > 1 && this.dialog.checked.shift()
      }
    },
    // 店铺列表
    getShopList () {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          let childList = JSON.parse(JSON.stringify(data.childList))
          let allChildList = []
          data.childList.forEach(child => {
            child.childList.forEach(shop => {
              allChildList.push(shop)
            })
          })
          let allShop = {
            name: '全部',
            childList: allChildList
          }
          childList.unshift(allShop)
          this.allShopList = JSON.parse(JSON.stringify(childList))
          this.childList = JSON.parse(JSON.stringify(childList))
          this.dialog.activeName =
            this.allShopList.length > 0 ? this.allShopList[0].name : null
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList
            }
          ]
        }
      })
    },

    // 获取列表
    async getItemList () {
      const { data, code, msg } = await this.$api.shopManageApi.getGoodsTagList()
      if (code === 200) {
        this.tableData = data
      }
    },

    // 点击添加按钮
    addItem () {
      const { dialog } = this
      dialog.title = '新增服务'
      dialog.action = 'add'
      dialog.show = true
      this.$refs.form && this.$refs.form.clearValidate()
    },
    changeItem (row) {
      const { dialog } = this
      dialog.title = '编辑服务'
      dialog.action = 'edit'
      dialog.name = row.name
      dialog.id = row.id
      dialog.shopName = row.shopName
      dialog.serviceExplain = row.serviceExplain
      dialog.checked = [row.shopName + 'id:' + row.shopId]
      dialog.show = true
      this.$refs.form && this.$refs.form.clearValidate()
    },
    // 删除
    async deleteItem (row) {
      const { code, msg } = await this.$api.shopManageApi.deleteServiceTag({id: row.id})
      if (code == 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(msg)
      }
    },
    // dialog确定
     confirmDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { id, name, serviceExplain, action } = this.dialog
          const postData = {
            id: action == 'edit' ? id : undefined,
            name,
            serviceExplain,
            shopIds: this.checkedShopIds
          }
          if (action == 'edit') {
            this.handleEditServiceTag(postData)
          } else {
            this.handleAddServiceTag(postData)
          }
        }
      })
    },
    // dialog取消
    cancelDialog () {
      this.handleResetDialog()
    },
    async handleAddServiceTag (postData) {
      const data = await this.$api.shopManageApi.addServiceTag(postData)
      if (data.code === 200) {
        this.$message.success('添加成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },
    async handleEditServiceTag (postData) {
      const data = await this.$api.shopManageApi.addServiceTag(postData)
      if (data.code === 200) {
        this.$message.success('修改成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },

    // dialog关闭
    dialogClose () {
      this.handleResetDialog()
    },
  }

}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.floor {
  margin-top: 10px;
  .floorShop {
    width: 100%;
    min-height: 300px;
    height: 300px;
    border: 1px solid #ccc;
    padding: 20px 10px;
    box-sizing: border-box;
    font-size: 12px;
    overflow: auto;
    .el-checkbox {
      margin: 20px 0 0 15px;
      margin-top: 20px;
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-checkbox:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  margin-top: 20px;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }
  .tag-list{
    .tags{
      .first{
        display: flex;
        align-items: center;
        height: 36px;
        .title{
          width: 180px;
          height: 36px;
          line-height: 36px;
          border-radius: 18px;
          border: 1px solid #ccc;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }
        .action{
          margin-left: 20px;
        }

      }
      .second{
        display: flex;
        margin: 10px 0;
        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }
        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
    }
  }
}

.d-paging {
  text-align: center;
}

::v-deep .el-form {
  width: 80%;
  margin: 0 auto;

  .el-select {
    width: 100%;
  }

  .el-range-editor.el-input__inner {
    width: 100%;
  }

  .hour {
    position: relative;
    display: flex;

    .vertical {
      display: inline-block;
      margin: 0 2%;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input {
      width: 90%;

      & > :last-child {
        margin-left: 10%;
      }
    }

    .addBtn {
      position: absolute;
      right: -40px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px dashed #ccc;
      font-size: 28px;
      color: #ccc;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-bottom: 4px;
      padding-left: 0.5px;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}
</style>
